import { FadeInContainer } from "../../components/ReusableStyledComponents/FadeIn"
import { TrackPageView } from "../../components/TrackPageView"
import { VerticalSpacing } from "../../components/VerticalSpacing"
import Layout from "../Layout"
import { SignupHeader, SignupLayoutContainer, SignupLayoutContainerWrapper, SignupLayoutTitleDescriptionWrapper, SignupLeftsideContainer, SignupParagraph, V3SignupHeadline3 } from "./SignupLayout.styles"

interface SignupLayoutProps {
    leftSideMaxWidth?: number,
    title?: string,
    pageTitle?: string,
    showHeader?: boolean,
    description?: string | JSX.Element,
    rightSidecontent?: JSX.Element,
    rightSideMaxWidth?: number
    leftSideContent?: JSX.Element,
    topContent?: JSX.Element,
    hideRightSideMobile?: boolean,
    trackPageViewHandler?: () => void,
    minHeight?: string,
    isPreview?: boolean,
    rightSideBiggerPadding?: boolean,
}

export const SignupLayout = ({ leftSideMaxWidth, title, description, rightSidecontent, rightSideMaxWidth, leftSideContent, topContent, showHeader = true, hideRightSideMobile = false, pageTitle, trackPageViewHandler, minHeight, isPreview = false, rightSideBiggerPadding = false }: SignupLayoutProps) => {
    return <Layout title={pageTitle || ""} skipAuthorytitle>
        {
            !!trackPageViewHandler && <TrackPageView onRouteChange={trackPageViewHandler} />
        }
        {!!topContent && <>{topContent}</>}
        <SignupLayoutContainerWrapper>
            <FadeInContainer>
                <SignupLayoutContainer $rightSideMaxWidth={rightSideMaxWidth} $hideRightSideMobile={hideRightSideMobile} $minHeight={minHeight} $rightSideBiggerPadding={rightSideBiggerPadding}>
                    <div>
                        <SignupLeftsideContainer $leftSideMaxWidth={leftSideMaxWidth}>
                            {
                                showHeader && <SignupHeader $isPreview={isPreview}>
                                    <div>
                                        <img src="/authory_static_files/images/icons/authory_logo_expanded.svg" width="165" height="54" />
                                    </div>
                                </SignupHeader>
                            }
                            <SignupLayoutTitleDescriptionWrapper $isPreview={isPreview}>
                                {
                                    !!title?.length && <VerticalSpacing top={48}>
                                        <V3SignupHeadline3 as="h1" $hideRightSideMobile={hideRightSideMobile}>{title}</V3SignupHeadline3>
                                    </VerticalSpacing>
                                }
                                {
                                    !!description && <>
                                        {
                                            typeof description === "string" ? <VerticalSpacing top={16}>
                                                <SignupParagraph as="h2">{description}</SignupParagraph>
                                            </VerticalSpacing> : <>{description}</>
                                        }
                                    </>
                                }
                            </SignupLayoutTitleDescriptionWrapper>
                            {leftSideContent}
                        </SignupLeftsideContainer>
                    </div>
                    {
                        rightSidecontent && <div>
                            <div>
                                {rightSidecontent}
                            </div>
                        </div>
                    }
                </SignupLayoutContainer>
            </FadeInContainer>
        </SignupLayoutContainerWrapper>
    </Layout>
}
import styled from "styled-components";
import { V3CaptionGrey35 } from "../../../components/ReusableStyledComponents/V3CaptionGrey35";
import { V3HeadlineH5, V3Paragraph } from "../../../components/Typography";

export const SignupSeparatorWrapper = styled.div`
    position: relative;
`;

export const SignupHR = styled.hr`
    height: 1px;
    background: ${({ theme }) => theme.v3.colors.grey88};
    border: none;
`;

export const SignupOR = styled.span`
    background-color: #FFFFFF;
    padding: 5px 9px;
    text-transform: uppercase;
    position: absolute;
    border: 1px solid #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    ${V3CaptionGrey35} {
        font-size: 10px;
        line-height: 18px;
    }
`;

export const CARightsideContent = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 360px;
    text-align: center;
    padding: 0 10px;
`;

export const CARightsideContentQuote = styled(V3Paragraph)`
    color: ${({ theme }) => theme.v3.colors.grey48};
    font-size: 24px;
    line-height: 32px;
`;

export const CARightSideMeta = styled(V3HeadlineH5)`
    color: ${({ theme }) => theme.v3.colors.grey35};
    font-weight: ${({ theme }) => theme ? `${theme.typography.weight.regular}` : null};

    span {
        font-weight: ${({ theme }) => theme ? `${theme.typography.weight.semibold}` : null};
        color: ${({ theme }) => theme.v3.colors.blue};
    }
`;

export const CARightSideImg = styled.img`
    width: 100%;
    height: auto;
    position: relative;
    top: 1px;
    transform: matrix(1.3, 0, 0, 1.3, 0, 0);
`;

export const CARLogosWrapper = styled.div`
    padding-top: calc(100vh * 0.1);
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
        flex-shrink: 0;

        &:nth-child(1) {
            width: calc(60% - 10px);
        }

        &:nth-child(2) {
            width: calc(40% - 10px);
        }
    }

    img {
        width: 100%;
        height: auto;
    }
`;
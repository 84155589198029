import { CreateAccountBodyParams } from "authory-api-types/dist/types"
import { useFacebookSDK } from "../../hooks/facebookSdkHook"
import { logError } from "../../logging/logSentryError"
import { V3Button } from "../Button"
import { V3ButtonColor } from "../Button/V3Button.types"
import { V3IconFacebook } from "../Icons"

interface V3FacebookButtonProps {
    createAccount: (credentials: CreateAccountBodyParams) => Promise<void>,
    mutationLoading: boolean
}

export const V3FacebookButton = ({ createAccount, mutationLoading }: V3FacebookButtonProps) => {

    useFacebookSDK(process.env.NEXT_PUBLIC_FACEBOOK_AUTH_CLIENT_ID!, true);

    const save = async (response: fb.StatusResponse) => {
        if (response.authResponse === null) {
            return;
        }

        try {
            await createAccount({
                facebookAccountId: response.authResponse.userID,
                facebookAuthToken: response.authResponse.accessToken!
            });
        } catch { }
    };

    const startOauth = () => {
        try {
            if (FB) {
                FB.login((loginResponse) => save(loginResponse), { scope: "public_profile,email,user_link" })
            }
        } catch (error) {
            logError(error);
        }
    };

    return <V3Button
        onClick={startOauth}
        color={V3ButtonColor.secondary}
        text="Continue with Facebook"
        icon={<V3IconFacebook width="21" height="20" />}
        skipIconStyling
        disabled={mutationLoading}
    />
}
import { CreateAccountBodyParams, SignInPayload } from "authory-api-types/dist/types";
import { FormikHelpers } from "formik";
import { InlineLink } from "../../../components/ReusableStyledComponents/InlineLink";
import { V3BodyGrey35 } from "../../../components/ReusableStyledComponents/V3BodyGrey35";
import { V3CaptionGrey35 } from "../../../components/ReusableStyledComponents/V3CaptionGrey35";
import { VerticalSpacing } from "../../../components/VerticalSpacing";
import { SignupLayout } from "../../../layout/SignupLayout";
import {
    SignupSeparatorWrapper,
    SignupHR,
    SignupOR,
    CARightsideContent,
    CARightsideContentQuote,
    CARightSideMeta,
    CARightSideImg,
    CARLogosWrapper
} from "./V3CreateAccountPageLayout.styles";
import { EmailCreateAccount, V3EmailButton } from "../../../components/EmailLogin";
import { V3FacebookButton } from "../../../components/FacebookLogin";
import { V3GoogleButton } from "../../../components/GoogleLogin";
import { APPLICATION_ROUTES } from "../../../types/routes";
import { V3TwitterButton } from "../../../components/TwitterLogin";

interface V3CreateAccountPageLayoutProps {
    createAccount: (credentials: CreateAccountBodyParams | SignInPayload) => Promise<void>;
    signupViaEmail: (formValues: EmailCreateAccount, { setFieldError }: FormikHelpers<EmailCreateAccount>) => any;
    mutationLoading: boolean;
    login?: boolean;
    trackPageViewHandler?: () => void;
}

export const V3CreateAccountPageLayout = ({
    createAccount,
    signupViaEmail,
    mutationLoading,
    login = false,
    trackPageViewHandler
}: V3CreateAccountPageLayoutProps) => {
    const signupTitle = "Let's get your account set up";

    return (
        <SignupLayout
            minHeight="calc(100vh - 24px)"
            trackPageViewHandler={trackPageViewHandler}
            title={login ? "Log in" : signupTitle}
            pageTitle={login ? "Log in" : signupTitle}
            description={login ? "You can log in to your Authory account below." : "Creating a portfolio will take less than 5 minutes."}
            leftSideMaxWidth={496}
            rightSideMaxWidth={480}
            hideRightSideMobile
            rightSideBiggerPadding
            rightSidecontent={
                <>
                    <CARightsideContent>
                        <CARLogosWrapper>
                            <div>
                                <img src="/authory_static_files/images/icons/new_york_times.svg" alt="new york times logo" />
                            </div>
                            <div>
                                <img src="/authory_static_files/images/icons/cbs_logo.svg" alt="cbs logo" />
                            </div>
                        </CARLogosWrapper>
                        <VerticalSpacing bottom={36}>
                            <CARightsideContentQuote>
                                “I can't believe I once <br />
                                muddled along without Authory. It's beautiful.”
                            </CARightsideContentQuote>
                        </VerticalSpacing>
                        <CARightSideMeta>
                            <strong>
                                <span>David Pogue</span>
                            </strong>
                            <br /> 6x Emmy Award Winner, Tech Columnist
                        </CARightSideMeta>
                        <VerticalSpacing top={100}>
                            <CARightSideImg src="/authory_static_files/images/signup/david_profile.png" />
                        </VerticalSpacing>
                    </CARightsideContent>
                </>
            }
            leftSideContent={
                <>
                    {login ? (
                        <>
                            <VerticalSpacing top={36}>
                                <V3GoogleButton createAccount={createAccount} mutationLoading={mutationLoading} />
                            </VerticalSpacing>
                            <VerticalSpacing top={6}>
                                <V3FacebookButton createAccount={createAccount} mutationLoading={mutationLoading} />
                            </VerticalSpacing>
                            <VerticalSpacing top={6}>
                                <V3TwitterButton createAccount={createAccount} mutationLoading={mutationLoading} />
                            </VerticalSpacing>
                        </>
                    ) : (
                        <>
                            <VerticalSpacing top={36}>
                                <V3GoogleButton createAccount={createAccount} mutationLoading={mutationLoading} PLGSignupFlow />
                            </VerticalSpacing>
                        </>
                    )}
                    <VerticalSpacing top={24} bottom={24}>
                        <SignupSeparatorWrapper>
                            <SignupHR />
                            <SignupOR>
                                <V3CaptionGrey35>
                                    <strong>OR</strong>
                                </V3CaptionGrey35>
                            </SignupOR>
                        </SignupSeparatorWrapper>
                    </VerticalSpacing>
                    <VerticalSpacing bottom={48}>
                        <V3EmailButton signupViaEmail={signupViaEmail} mutationLoading={mutationLoading} login={login} />
                    </VerticalSpacing>
                    {!login && (
                        <VerticalSpacing bottom={5}>
                            <V3BodyGrey35>
                                By continuing in you'll agree to our{" "}
                                <InlineLink href={APPLICATION_ROUTES.TERMS} target="_blank">
                                    <strong>Terms and Conditions</strong>
                                </InlineLink>{" "}
                                and{" "}
                                <InlineLink href={APPLICATION_ROUTES.PRIVACY} target="_blank">
                                    <strong>Privacy Policy</strong>
                                </InlineLink>
                                .
                            </V3BodyGrey35>
                        </VerticalSpacing>
                    )}
                    <VerticalSpacing bottom={48}>
                        <V3BodyGrey35>
                            {login ? "Don't have an Authory account yet? " : "Already have an account? "}
                            <InlineLink href={login ? APPLICATION_ROUTES.SIGNUP : APPLICATION_ROUTES.LOGIN}>
                                <strong>{login ? "Sign up" : "Log in"}</strong>{" "}
                            </InlineLink>
                        </V3BodyGrey35>
                    </VerticalSpacing>
                </>
            }
        />
    );
};

import { useEffect, useState } from "react";
import { V3Button } from "../Button";
import { V3ButtonColor } from "../Button/V3Button.types";
import { jwtDecode } from "jwt-decode";
import { logError } from "../../logging/logSentryError";
import { CreateAccountBodyParams } from "authory-api-types/dist/types";

interface V3GoogleLoginProps {
    createAccount: (credentials: CreateAccountBodyParams) => Promise<void>;
    mutationLoading: boolean;
    PLGSignupFlow?: boolean;
}

export const V3GoogleButton = ({ createAccount, PLGSignupFlow = false, mutationLoading }: V3GoogleLoginProps) => {
    const [googleLoginWrapperButton, setGoogleLoginWrapperButton] = useState<Element | null>(null);

    const signupViaGoogle = async (googleUser: any) => {
        const decodedJWT = jwtDecode(googleUser.credential);
        const id = decodedJWT.sub!;

        const params: CreateAccountBodyParams = {
            googleAccountId: id,
            googleAuthToken: googleUser.credential
        };

        try {
            await createAccount(params);
        } catch (error) {
            logError(error);
        }
    };

    const init = () => {
        if (
            //@ts-ignore
            window.google &&
            //@ts-ignore
            window.google.accounts &&
            //@ts-ignore
            window.google.accounts.id?.initialize
        ) {
            //@ts-ignore
            const goo = window.google.accounts.id.initialize({
                client_id: process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID,
                callback: signupViaGoogle
            });

            const googleLoginWrapper = document.createElement("div");
            // Or you can simple hide it in CSS rule for custom-google-button
            googleLoginWrapper.style.display = "none";
            googleLoginWrapper.classList.add("custom-google-button");

            // Add the wrapper to body
            document.body.appendChild(googleLoginWrapper);

            // Use GSI javascript api to render the button inside our wrapper
            // You can ignore the properties because this button will not appear
            //@ts-ignore
            window.google.accounts.id.renderButton(googleLoginWrapper, {
                type: "icon",
                width: "200"
            });

            const googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");

            setGoogleLoginWrapperButton(googleLoginWrapperButton);
        }
    };

    useEffect(() => {
        try {
            //@ts-ignore
            if (window.google) {
                init();
            } else {
                const meta = document.createElement("meta");
                meta.name = "google-signin-client_id";
                (meta.content = process.env.NEXT_PUBLIC_GOOGLE_AUTH_CLIENT_ID!), document.head.appendChild(meta);

                const script = document.createElement("script");
                script.src = "https://accounts.google.com/gsi/client";
                script.async = true;
                script.defer = true;
                // calls init
                script.addEventListener("load", init);
                document.head.appendChild(script);
            }
        } catch {}
    }, []);

    return (
        <V3Button
            color={PLGSignupFlow ? V3ButtonColor.primary : V3ButtonColor.secondary}
            text="Continue with Google"
            icon={
                <img
                    src="/authory_static_files/images/google-icon.svg"
                    width="28px"
                    style={
                        PLGSignupFlow
                            ? {
                                  transform: "translateY(-50%)",
                                  top: "50%",
                                  left: "8px",
                                  position: "absolute"
                              }
                            : undefined
                    }
                />
            }
            skipIconStyling
            disabled={mutationLoading}
            relativePosition={PLGSignupFlow}
            onClick={() => {
                //@ts-ignore
                if (googleLoginWrapperButton) googleLoginWrapperButton.click();
            }}
        />
    );
};

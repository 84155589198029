import { useEffect } from "react";
import { logError } from "../logging/logSentryError";

export const useFacebookSDK = (appId: string, logPageView = false) => {
    const initFacebook = () => {
        FB.init({
            appId,
            autoLogAppEvents: true,
            xfbml: true,
            version: "v15.0"
        });

        if (logPageView) {
            // Log page view so we boost our users.
            // https://www.facebook.com/help/analytics/1747887018756757
            FB.AppEvents.logPageView();
        }
    };

    useEffect(() => {
        try {
            if (window.FB) {
                initFacebook();
            } else {
                const fbScript = document.createElement("script");
                fbScript.src = "https://connect.facebook.net/en_US/sdk.js";
                fbScript.async = true;
                fbScript.defer = true;
                // Calls startOauth
                fbScript.addEventListener("load", initFacebook);
                document.head.appendChild(fbScript);
            }
        } catch (error) {
            logError(error);
        }
    }, []);
}
import { CreateAccountBodyParams } from "authory-api-types/dist/types"
import { getTwitterAuthLink } from "../../api/auth";
import { logAxiosError } from "../../logging/logSentryError";
import { V3Button } from "../Button"
import { V3ButtonColor } from "../Button/V3Button.types"
import { V3IconTwitter } from "../Icons"

const POLL_INTERVAL = 100;

interface V3TwitterButtonProps {
    createAccount: (credentials: CreateAccountBodyParams) => Promise<void>,
    mutationLoading: boolean,
}

export const V3TwitterButton = ({ createAccount, mutationLoading }: V3TwitterButtonProps) => {

    const startOauth = async () => {
        // Open popup first, while we are still in click context.
        const popup = window.open("about:blank")!;

        let res;

        try {
            res = await getTwitterAuthLink();
        } catch (error) {
            logAxiosError(error as any);
        }

        if (!res) return;

        popup.location.href = res.redirectUrl;
        const requestTokenSecret = res.twitterRequestTokenSecret;

        const pollTimer = window.setInterval(async () => {
            try {
                const url = popup.document.URL; // Fails if cross-domain, so will only proceed if it's been redirected to /signup/twitter-callback

                if (url.indexOf("/signup/twitter-callback") !== -1) {
                    window.clearInterval(pollTimer);

                    const urlParams = new URLSearchParams(url.split("?")[1]);
                    const oauth_token = urlParams.get("oauth_token");
                    const oauth_verifier = urlParams.get("oauth_verifier");

                    if (typeof oauth_token === "string" && typeof oauth_verifier === "string") {
                        popup.close();

                        try {
                            await createAccount({
                                twitterRequestToken: oauth_token,
                                twitterRequestVerifier: oauth_verifier,
                                twitterRequestTokenSecret: requestTokenSecret,
                            });
                        } catch { }
                    }
                }
            } catch (error) {
                // This one we cant log or stop, we need to wait for the user to be redirected.
            }
        }, POLL_INTERVAL);
    }

    return <V3Button
        color={V3ButtonColor.secondary}
        text="Continue with Twitter"
        icon={<V3IconTwitter width="20" height="20" />}
        skipIconStyling
        onClick={startOauth}
        disabled={mutationLoading}
    />
}
import * as Yup from "yup";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import { V3Button } from "../Button"
import { V3ButtonColor } from "../Button/V3Button.types"
import { V3IconNewsletter } from "../Icons";
import { V3TextInput } from "../TextInput/V3TextInput";
import { VerticalSpacing } from "../VerticalSpacing";
import { OptionalName, PasswordCredential } from "authory-api-types/dist/types";
import { useRouter } from "next/router";
import { APPLICATION_ROUTES } from "../../types/routes";
import { CreateAccountEmailNameWrapper } from "./V3EmailButton.styles";

export type EmailCreateAccount = PasswordCredential & OptionalName;

interface V3EmailButtonProps {
    signupViaEmail: (formValues: EmailCreateAccount, { setFieldError }: FormikHelpers<EmailCreateAccount>) => any,
    mutationLoading: boolean,
    login?: boolean,
}

export const V3EmailButton = ({ signupViaEmail, mutationLoading, login = false }: V3EmailButtonProps) => {
    const [expanded, setExpanded] = useState(false);
    const router = useRouter();

    const nameValidation = !login ? {
        firstName: Yup.string()
            .trim()
            .ensure()
            .required("Please enter a first name"),
        lastName: Yup.string()
            .trim()
            .ensure()
            .required("Please enter a last name")
    } : undefined;

    const initialData = !login ? { email: '', password: '', firstName: '', lastName: '' } : { email: '', password: '' }

    const formik = useFormik<EmailCreateAccount>({
        initialValues: initialData,
        onSubmit: signupViaEmail,
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .ensure()
                .email("Enter a valid email address")
                .required("Please enter an email address"),
            password: Yup.string()
                .min(5, "Please enter a password with at least 5 characters")
                .required("Please enter a password"),
            ...nameValidation
        })
    });
    return <>
        {!expanded && <V3Button
            color={V3ButtonColor.secondary}
            text="Continue with Email"
            icon={<V3IconNewsletter style={!login ? {
                transform: "translateY(-50%)",
                top: "50%",
                left: "9px",
                position: "absolute",
            } : undefined} />}
            onClick={() => setExpanded(true)}
            skipIconStyling
            relativePosition={!login}
        />}
        {
            expanded && <>
                <form onSubmit={formik.handleSubmit}>
                    <V3TextInput
                        autoFocus
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="e.g. jonhdoe@gmail.com"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values?.email}
                        error={formik.errors.hasOwnProperty("email") && formik.touched.hasOwnProperty("email") ? formik.errors.email : undefined}
                        disabled={mutationLoading}
                    />
                    <VerticalSpacing top={18}>
                        <V3TextInput
                            label="Password"
                            type="password"
                            name="password"
                            placeholder="e.g. **********"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            role="password"
                            value={formik.values.password}
                            error={formik.errors.hasOwnProperty("password") && formik.touched.hasOwnProperty("password") ? formik.errors.password : undefined}
                            disabled={mutationLoading}
                            actionText={login ? "Forgot password?" : undefined}
                            actionClickHandler={login ? () => { router.push(APPLICATION_ROUTES.LOGIN_FORGOTTEN_PASSWORD) } : undefined}
                        />
                    </VerticalSpacing>
                    {
                        !login && <>
                            <VerticalSpacing top={18}>
                                <CreateAccountEmailNameWrapper>
                                    <div>
                                        <V3TextInput
                                            label="First name"
                                            name="firstName"
                                            value={formik.values.firstName}
                                            placeholder={"e.g. John"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={mutationLoading}
                                            error={formik.errors.hasOwnProperty("firstName") && formik.touched.hasOwnProperty("firstName") ? formik.errors.firstName : undefined}
                                        />
                                    </div>
                                    <div>
                                        <V3TextInput
                                            label="Last name"
                                            name="lastName"
                                            value={formik.values.lastName}
                                            placeholder={"e.g. Doe"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            disabled={mutationLoading}
                                            error={formik.errors.hasOwnProperty("lastName") && formik.touched.hasOwnProperty("lastName") ? formik.errors.lastName : undefined}
                                        />
                                    </div>
                                </CreateAccountEmailNameWrapper>
                            </VerticalSpacing>
                        </>
                    }
                    <VerticalSpacing top={36}>
                        <V3Button
                            text={login ? "Login" : "Create account"}
                            type="submit"
                            disabled={mutationLoading}
                        />
                    </VerticalSpacing>
                </form>
            </>
        }
    </>
}
import axios, { AxiosError } from "axios";
import {
    TwitterAuthLinkResponse,
    SignInPayload
} from "authory-api-types/dist/types/signup";
import {
    SignIn as SignInEndpoint,
    GetTwitterAuthLink as GetTwitterAuthLinkEndpoint,
    UserExists as UserExistsEndpoint
} from "authory-api-types/dist/endpoints";
import { Errors } from "../types/api";
import { logAxiosError } from "../logging/logSentryError";
import { API_URL } from "./index";
import { NotificationInlineLink } from "../components/ReusableStyledComponents/NotificationInlineLink";
import { NotificationHandler } from "./error-handler";
import { APPLICATION_ROUTES } from "../types/routes";
import { UserSignInResponse } from "authory-api-types/dist/types";
import { LoginMethod } from "authory-api-types/dist/enums";

export const signIn = async (credentials: SignInPayload) => {
    const { data } = await axios.post<UserSignInResponse>(
        `${API_URL}${SignInEndpoint}`,
        credentials
    );

    return data;
};

const getLoginMethod = (variables: SignInPayload) => {
    if ('email' in variables && 'password' in variables) {
        return LoginMethod.Password;
    } else if ('twitterRequestToken' in variables && 'twitterRequestVerifier' in variables && 'twitterRequestTokenSecret' in variables) {
        return LoginMethod.Twitter;
    } else if ('facebookAccountId' in variables && 'facebookAuthToken' in variables) {
        return LoginMethod.Twitter;
    } else if ('googleAccountId' in variables && 'googleAuthToken' in variables) {
        return LoginMethod.Google;
    }
}

export const onV3SignInError = (toastHandler: NotificationHandler,) => async (error: AxiosError, variables: SignInPayload) => {
    const data = JSON.parse(error?.config.data);

    const loginMethod = getLoginMethod(variables);
    const hasLoginMethod = !!error?.response?.data?.userLoginMethod
    const isWrongLoginMethod = loginMethod !== error?.response?.data?.userLoginMethod;

    if (error?.response?.status === Errors.AccessDenied && error?.response?.data?.userLoginMethod && hasLoginMethod && isWrongLoginMethod) {
        toastHandler(<span>Looks like your Authory account has been created with a {error?.response.data?.userLoginMethod.replace(LoginMethod.Password, "email")} login. Please select the respective option above.</span>, { duration: 7000 });
    } else if (error?.response?.status === Errors.AccessDenied && hasLoginMethod && !isWrongLoginMethod) {
        toastHandler(<span>It looks like the email address or password you entered are not quite right. <NotificationInlineLink href={`${APPLICATION_ROUTES.LOGIN_FORGOTTEN_PASSWORD}?email=${encodeURIComponent(data.email)}`}>Forgot your password?</NotificationInlineLink></span>)
    } else if (error?.response?.status === Errors.AccessDenied) {
        toastHandler(<span>Sorry, we couldn't find any account connected with your chosen login method. Please double check, and if it still does not work contact <NotificationInlineLink href='mailto:hello@authory.com?subject=Login'>hello@authory.com</NotificationInlineLink>.</span>, { duration: 7000 });
    } else {
        toastHandler(<span>Sorry, something went wrong. Please try again and if it still doesn't work contact <NotificationInlineLink href='mailto:hello@authory.com?subject=Signup'>hello@authory.com</NotificationInlineLink>.</span>);
        logAxiosError(error);
    }
};

export const userExists = async (email: string) => {
    const { data } = await axios.post<{ exists: boolean }>(
        `${API_URL}${UserExistsEndpoint}`,
        { email }
    );

    return data;
};

export const getTwitterAuthLink = async () => {
    const { data } = await axios.get<TwitterAuthLinkResponse>(
        `${API_URL}${GetTwitterAuthLinkEndpoint}`
    );

    return data;
};
